import React from 'react';
import {ProgramMessagesBox} from "./Partials/ProgramMessagesBox";
import {DocumentBox} from "./Partials/DocumentBox";

export const Dashboard = () => {
    const staticUrl = process.env.REACT_APP_STATIC_URL;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-6">
                    <DocumentBox/>
                </div>
                <div className="col-sm-6">
                    <ProgramMessagesBox/>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-sm-6">
                    <p><strong>Naziv projekta:</strong> Zdrava radna mjesta u turizmu</p>
                    <p><strong>Nositelj projekta:</strong> Učilište Lovran, Opatija</p>
                    <p><strong>Partneri:</strong> Ortosan d.o.o., Lovran/Opatija, Pučko otvoreno učilište Petar Zrinski, Zagreb</p>
                    <p><strong>Vrijednost projekta:</strong> 1.361.677,44 kn</p>
                    <p><strong>Trajanje projekta:</strong> 24 mjeseca, travanj 2019 – travanj 2021</p>
                    <p><strong>EU sufinanciranje:</strong> 100%</p>
                    <p><strong>Područje provedbe projekta:</strong> PGŽ, Istarska županija, Grad Zagreb, Zagrebačka i Karlovačka
                        županija</p>
                </div>
                <div className="col-sm-6">
                    <p><strong>Korisnici projekta:</strong></p>
                    <ul className="user-profiles">
                        <li>nezaposleni mlađi od 25 godina – 34 osobe</li>
                        <li>nezaposleni stariji od 54 godina – 6 osoba</li>
                        <li>nezaposleni osobe s invaliditetom – 4 osobe</li>
                    </ul>
                    <p><strong>Program:</strong> Europski socijalni fond (ESF), OP – Učinkoviti ljudski potencijali 2014.-2020.;
                        Poboljšanje pristupa ranjivih skupina tržištu rada u sektoru turizma i ugostiteljstva</p>
                    <p><strong>Opći cilj</strong> ovog projekta je ostvariti preduvjete za poboljšanje zapošljivosti i samozapošljivosti
                        ranjivih skupina u selektivnoj turističkoj grani zdravstvenom i wellness turizmu.</p>
                </div>
            </div>
            <img className="img-responsive mw-100" src={staticUrl + 'images/footer-petar-zrinski.png'} alt="Project Logo" />
        </React.Fragment>
    )
};
