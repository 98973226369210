import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});

export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
});

api.interceptors.request.use((request) => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
    return request;
});

export const handleGlobalApiErrors = (setMessage, setIsLoggedIn) => {
    return api.interceptors.response.use(undefined, async (error) => {
        const status = error.response ? error.response.status : 0;
        if(error.config && (status === 401 || status === 400)) {
            const isRefreshed = await refreshToken();
            if(isRefreshed) {
                error.config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
                setMessage({message: '', type: ''});
                return api.request(error.config);
            } else {
                setIsLoggedIn(false);
                setMessage({message: 'Molimo Vas da ponovo ulogirate', type: 'info'});
            }
        } else if (status === 403) {
            setIsLoggedIn(false);
            setMessage({message: error.response.data.error_message, type: 'danger'});
        } else if (status === 422) {
            setMessage({message: error.response.data.error_message, type: 'danger'});
        }
        return Promise.reject(error);
    });
};

// Returns a promise with result if the token is refreshed or not
export const refreshToken = async () => {
    try {
        const result = await publicApi.post('token/refresh', { 'refresh': localStorage.getItem('refresh_token') });
        if(result) {
            localStorage.setItem('access_token', result.data.access);
            return true;
        }
    } catch {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        return false;
    }
    return false;
};
