import React from 'react';

import { Login, Error } from '../Components';

export const LoginLayout = () => {
    return(
        <div className="container">
            <Error />
            <div className="row">
                <div className="offset-lg-4 col-lg-4">
                    <Login />
                </div>
            </div>
        </div>
    )
};
