import React, {useContext} from 'react';
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom';
import {MainLayout, LoginLayout} from './Layouts';
import {GlobalContext} from './Context';
import {
    Dashboard,
    NotFound404,
    Documents,
    Program,
    Messages,
    Students,
    Teachers,
    Login,
    ProgramPreview,
    ChangePassword
} from './Components';
import {MessagePreview, MessagesContextProvider, ProgramMessagePreview} from "./Components/Messages";

const PrivateRoute = ({component: Component, context: Context, ...rest}) => {
    const {isLoggedIn} = useContext(GlobalContext);

    return (
        <Route {...rest} render={props => {
            let content = <Component {...props} />;
            if (Context) {
                content = <Context><Component {...props} /></Context>
            }
            return (
                isLoggedIn
                    ? (<MainLayout>{content}</MainLayout>)
                    : (<Redirect to='/login'/>)
            )
        }}/>
    );
};

const AdminRoute = ({component: Component, context: Context, ...rest}) => {
    const {isLoggedIn, userInfo} = useContext(GlobalContext);

    return (
        <Route {...rest} render={props => {
            let content = <Component {...props} />;
            if (Context) {
                content = <Context><Component {...props} /></Context>
            }
            return (
                isLoggedIn && userInfo.permission === 'admin'
                    ? (<MainLayout>{content}</MainLayout>)
                    : (<Redirect to='/'/>)
            )
        }}/>
    );
};

const PublicRoute = ({component: Component, ...rest}) => {
    const {isLoggedIn} = useContext(GlobalContext);

    return (
        <Route {...rest} render={props => (
            isLoggedIn
                ? (<Redirect to='/'/>)
                : (<LoginLayout><Component {...props} /></LoginLayout>)
        )}/>
    );
};

function App() {
    return (
        <HashRouter>
            <Switch>
                <PrivateRoute exact path='/' component={Dashboard}/>
                <PrivateRoute exact path='/program' component={Program}/>
                <PrivateRoute exact path='/program/:id(\d+)' component={ProgramPreview}/>
                <PrivateRoute exact path='/documents' component={Documents}/>
                <PrivateRoute exact path='/messages' component={Messages} context={MessagesContextProvider}/>
                <PrivateRoute exact path='/messages/:id(\d+)' component={MessagePreview} context={MessagesContextProvider} />
                <PrivateRoute exact path='/program-message/:id(\d+)' component={ProgramMessagePreview} />
                <PrivateRoute exact path='/change-password' component={ChangePassword} />
                <AdminRoute exact path='/students' component={Students}/>
                <AdminRoute exact path='/teachers' component={Teachers}/>
                <PublicRoute exact path='/login' component={Login} context={''}/>
                <Route component={NotFound404}/>
            </Switch>
        </HashRouter>
    );
}

export default App;
