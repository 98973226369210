import React, {useContext, useEffect} from 'react';
import {GlobalContext} from "../../Context";

export const Error = () => {
    const {message, setMessage} = useContext(GlobalContext);

    useEffect(() => {
        if (message.message) {
            setTimeout(() => {
                setMessage({message: '', type: ''});
            }, 2000);
        }
    }, [message, setMessage]);

    return (
        <div
            className={`alert alert-${message.type} alert-dismissible fade text-center fixed-top ${message.message ? 'show' : ''}`}
            role="alert">
            {message.message}
        </div>
    )
};
