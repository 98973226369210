import React, {useContext, useEffect, useReducer, useState} from "react";
import {GlobalContext} from "../../../Context";
import {api} from "../../../Api";
import {Button, Input} from "../../../Elements";
import {ConfirmModal, FormModal} from "../..";
import $ from "jquery";

const documentFormInitialState = {
    'myFileName': '',
    'file': {},
    'chosenFileName': 'Odaberite datoteku',
    'documentToDelete': ''
};

const documentFormReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NAME':
            return {...state, myFileName: action.payload};
        case 'SET_FILE_UPLOAD':
            return {...state, file: action.payload, chosenFileName: action.payload.name};
        case 'EMPTY_FORM':
            return {...state, myFileName: '', chosenFileName: 'Odaberite datoteku', file: {}};
        case 'SET_DOCUMENT_TO_DELETE':
            return {...state, documentToDelete: action.payload};
        default:
            return state;
    }
};

export const DocumentTable = (props) => {
    const mediaDownloadLink = process.env.REACT_APP_MEDIA_URL;
    const {programId} = props;
    const {userInfo, setMessage} = useContext(GlobalContext);
    const [documentFormState, dispatchDocumentForm] = useReducer(documentFormReducer, documentFormInitialState);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-documents', {params: {program_id: programId}});
            if (res) setFiles(res.data);
        };
        awaitFunction();
    }, [programId]);

    const submitFile = async () => {
        if (documentFormState.file.name) {
            const data = new FormData();
            data.append('file', documentFormState.file);
            data.append('fileName', documentFormState.myFileName ? documentFormState.myFileName : documentFormState.chosenFileName);
            data.append('program_id', programId);
            const res = await api.post('post-program-document', data);
            if (res) {
                setFiles(res.data);
                $('#formModal').modal('hide');
                dispatchDocumentForm({type: 'EMPTY_FORM'})
            }
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    const deleteDocument = async (documentId) => {
        const res = await api.delete('delete-program-document', {params: {document_id: documentId, program_id: programId}});
        if (res) setFiles(res.data);
        $('#confirmModal').modal('hide');
    };

    return (
        <React.Fragment>
            {
                userInfo.permission === 'admin' || userInfo.permission === 'teacher'
                    ? <div className="content-header text-right">
                        <button className="btn btn-primary" data-toggle="modal" data-target="#formModal">
                            <i className="fas fa-plus-circle"/> Dodaj dokument
                        </button>
                    </div>
                    : null
            }
            <table className="table table-bordered table-sm">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Naziv</th>
                    <th scope="col" width="78px">Akcije</th>
                </tr>
                </thead>
                <tbody>
                {files.map((file, i) => (
                    <tr key={i}>
                        <td>{file.name}</td>
                        <td className="d-flex">
                            <a className="btn btn-success btn-sm mr-1 w-100" href={mediaDownloadLink + file.path}
                               download
                               target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-arrow-alt-circle-down"/>
                            </a>
                            {
                                userInfo.permission === 'admin' || userInfo.permission === 'teacher'
                                    ? <button
                                        className="btn btn-danger btn-sm"
                                        data-toggle="modal"
                                        data-target="#confirmModal"
                                        onClick={() => {
                                            dispatchDocumentForm({type: 'SET_DOCUMENT_TO_DELETE', payload: file.id})
                                        }}
                                    >
                                        <i className="fas fa-trash-alt" aria-hidden="true"/>
                                    </button>
                                    : null
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <FormModal
                title="Dodaj dokument"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={submitFile}
                >
                    Spremi
                </Button>}
            >
                <form>
                    <div className="form-group">
                        <label htmlFor="documentName">Naziv:</label>
                        <Input
                            type="text"
                            className="form-control"
                            id="documentName"
                            placeholder="Upišite naziv dokumenta..."
                            value={documentFormState.myFileName}
                            validation={[]}
                            onChange={ev => dispatchDocumentForm({type: 'SET_NAME', payload: ev.target.value})}/>
                    </div>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={ev => dispatchDocumentForm({
                                type: 'SET_FILE_UPLOAD',
                                payload: ev.target.files[0]
                            })}/>
                        <label className="custom-file-label"
                               htmlFor="customFile">{documentFormState.chosenFileName}</label>
                    </div>
                </form>
            </FormModal>
            <ConfirmModal
                title="Brisanje"
                question="Jeste li sigurni da želite obrisati dokument?"
                submitButton={
                    <button className="btn btn-danger"
                            onClick={() => deleteDocument(documentFormState.documentToDelete)}
                    >Obriši</button>
                }
            />
        </React.Fragment>
    );
};