import React, {useContext, useMemo} from 'react';
import {ProgramAdmin, ProgramStudent, ProgramTeacher} from './PermissionPartials';
import {GlobalContext} from "../../Context";

export const Program = () => {
    const {userInfo} = useContext(GlobalContext);

    const content = useMemo(() => {
        if (userInfo.permission === 'admin') {
            return <ProgramAdmin />
        }
        else if (userInfo.permission === 'teacher') {
            return <ProgramTeacher />
        }
        else if (userInfo.permission === 'student') {
            return <ProgramStudent />
        }
    }, [userInfo.permission]);

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
};
