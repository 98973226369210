import React, { useContext, useReducer } from 'react';
import { GlobalContext } from '../../Context';

const reduce = (state, action) => {
    switch(action.type) {
        case 'SET_EMAIL':
            return { ...state, email: action.payload };
        case 'SET_PASSWORD':
            return { ...state, password: action.payload };
        default:
            return state;
    }
};

export const Login = () => {
    const staticUrl = process.env.REACT_APP_STATIC_URL;
    const {login} = useContext(GlobalContext);
    const [state, dispatch] = useReducer(reduce, {
        email: '',
        password: ''
    });

    const onSubmitHandler = (ev) => {
        ev.preventDefault();
        login(state.email, state.password);
    };

    return (
        <React.Fragment>
            <img className="img-fluid mt-5" src={staticUrl + 'images/logo_platforme.svg'} alt="Logo" />
            <form className="mt-5">
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        className="form-control"
                        type="email"
                        aria-describedby="emailHelp"
                        value={state.email}
                        onChange={e => dispatch({ type: 'SET_EMAIL', payload: e.target.value })} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Lozinka:</label>
                    <input
                        id="password"
                        className="form-control"
                        type="password"
                        value={state.password}
                        onChange={e => dispatch({ type: 'SET_PASSWORD', payload: e.target.value })} />
                </div>
                <button className="btn btn-primary" onClick={(ev) => onSubmitHandler(ev)}>Prijavi se</button>
            </form>
        </React.Fragment>
    );
};
