import React, {useContext, useEffect, useReducer, useState} from 'react';
import {useHistory} from 'react-router';
import $ from 'jquery';
import {api} from '../../../Api';
import {ConfirmModal, FormModal} from "../..";
import {Button, Input, Select} from "../../../Elements";
import {GlobalContext} from "../../../Context";

const initialState = {
    'name': '',
    'teacher': '',
    'programToDelete': ''
};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NAME':
            return {...state, name: action.payload};
        case 'SET_TEACHER':
            return {...state, teacher: action.payload};
        case 'EMPTY_FORM':
            return {...state, name: '', teacher: ''};
        case 'SET_PROGRAM_TO_DELETE':
            return {...state, programToDelete: action.payload};
        default:
            return state;
    }
};

export const ProgramAdmin = () => {
    const {setMessage} = useContext(GlobalContext);
    const history = useHistory();
    const [programs, setPrograms] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [formState, dispatch] = useReducer(formReducer, initialState);

    useEffect(() => {
        const awaitFunction = async () => {
            const result = await api.get('get-programs');
            if (result) setPrograms(result.data);
        };
        awaitFunction();
    }, []);

    useEffect(() => {
        const awaitFunction = async () => {
            const result = await api.get('get-teachers');
            if (result) setTeachers(result.data);
        };
        awaitFunction();
    }, []);

    const createProgram = async () => {
        if (formState.name !== '') {
            const result = await api.post('post-program', formState);
            if (result) setPrograms(result.data);
            $('#formModal').modal('hide');
            setMessage({message: 'Program uspješno kreiran', type: 'success'});
            dispatch({type: 'EMPTY_FORM'})
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    const deleteProgram = async () => {
        const result = await api.delete('delete-program', {params: {'id': formState.programToDelete}});
        if (result) setPrograms(result.data);
        $('#confirmModal').modal('hide');
    };

    return (
        <React.Fragment>
            <div className="content-header text-right">
                <button className="btn btn-primary" data-toggle="modal" data-target="#formModal">
                    <i className="fas fa-plus-circle"/> Novi program
                </button>
            </div>
            <table className="table table-bordered table-hover table-selectable table-sm">
                <thead className="thead-dark">
                <tr>
                    <th scope="col" width="4%" className="text-center">#</th>
                    <th scope="col">Naziv</th>
                    <th scope="col">Profesor</th>
                    <th scope="col">Broj polaznika</th>
                    <th scope="col" width="6%">Akcije</th>
                </tr>
                </thead>
                <tbody>
                {programs.map((pr, i) => (
                    <tr key={i} onClick={() => history.push('/program/' + pr.id)}>
                        <th scope="row" className="text-center">{i + 1}</th>
                        <td>{pr.name}</td>
                        <td>{pr.teacher}</td>
                        <td>{pr.student_count}</td>
                        <td className="text-center">
                            <button
                                className="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#confirmModal"
                                onClick={ev => {
                                    ev.stopPropagation();
                                    dispatch({type: 'SET_PROGRAM_TO_DELETE', payload: pr.id})
                                }}
                            >
                                <i className="fas fa-trash-alt"/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <FormModal
                title="Kreiraj novi program"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={createProgram}
                >
                    Spremi
                </Button>}
            >
                <form>
                    <div className="form-group">
                        <label htmlFor="programName">Naziv:</label>
                        <Input
                            type="text"
                            className="form-control"
                            id="programName"
                            placeholder="Upišite naziv predmeta..."
                            value={formState.name}
                            validation={['empty']}
                            onChange={ev => dispatch({type: 'SET_NAME', payload: ev.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="teacherSelect">Profesor:</label>
                        <Select
                            className="form-control custom-select"
                            id="teacherSelect"
                            value={formState.teacher}
                            validation={['']}
                            onChange={ev => dispatch({type: 'SET_TEACHER', payload: ev.target.value})}>
                            <option value="">Odaberite profesora...</option>
                            {teachers.map((teacher, i) => (
                                <option key={i} value={teacher.id}>{teacher.full_name}</option>
                            ))}
                        </Select>
                    </div>
                </form>
            </FormModal>
            <ConfirmModal
                title="Brisanje"
                question="Jeste li sigurni da želite obrisati predmet?"
                submitButton={
                    <button className="btn btn-danger" onClick={deleteProgram}>Obriši</button>
                }
            />
        </React.Fragment>
    );
};
