import React, { useState, useContext } from 'react';
import { GlobalContext } from '../../Context';

export const Navigationbar = () => {
    const {sidebarVisible, setSidebarVisible, logout, userInfo} = useContext(GlobalContext);
    const [dropdownShow, setDropdownShow] = useState('');

    const onDropdownClicked = (ev) => {
        ev.preventDefault();
        if (dropdownShow === 'show') setDropdownShow('');
        else setDropdownShow('show');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
            <button
                className="btn toogle-sidebar-button"
                onClick={() => setSidebarVisible(!sidebarVisible)}>
                    <i className="fas fa-bars" />
            </button>

            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button> */}

            <span className="navbar-text align-middle">ePlatforma</span>

            <ul className="navbar-nav mt-2 mt-lg-0">
                <li className="nav-item dropdown">
                    <a
                        className="nav-link dropdown-toggle"
                        href="/#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={ev => onDropdownClicked(ev)}><i className="fas fa-user-circle" /></a>
                    <div className={"dropdown-menu dropdown-menu-right " + dropdownShow} aria-labelledby="navbarDropdown">
                        <h6 className="dropdown-header">Korisnik</h6>
                        <a className="dropdown-item disabled" href="/#">{userInfo.user}</a>
                        <a className="dropdown-item disabled" href="/#">{userInfo.program}</a>
                        <div className="dropdown-divider" />
                        {
                            userInfo.permission === 'admin'
                            ? <a className="dropdown-item" href={process.env.REACT_APP_ADMIN_URL} target="_blank" rel="noopener noreferrer">Admin</a>
                            : ''
                        }
                        <a className="dropdown-item" href="/#/change-password">Promjeni lozinku</a>
                        <button className="dropdown-item" onClick={() => logout()}>Odjavi se</button>
                    </div>
                </li>
            </ul>
        </nav>
    );
}
