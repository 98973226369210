import React from 'react';
import ReactDOM from 'react-dom';

export const FormModal = (props) => {
    return ReactDOM.createPortal(
        <div className="modal fade" id={props.id ? props.id : 'formModal'} tabIndex="-1" role="dialog" aria-labelledby="messageModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Zatvori</button>
                        {props.submitButton}
                    </div>
                </div>
            </div>
        </div>
        ,
        document.getElementById('root')
    );
};
