import React, {useContext, useEffect, useState} from "react";
import {api} from "../../../Api";
import {GlobalContext} from "../../../Context";
import {useHistory} from "react-router-dom";


export const ProgramMessagesBox = () => {
    const history = useHistory();
    const {userInfo} = useContext(GlobalContext);
    const [programMessages, setProgramMessages] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-messages', {params: {id: userInfo.program_id}});
            if (res) setProgramMessages(res.data.slice(0, 10));
        };
        awaitFunction();
    }, [userInfo]);

    return (
        <React.Fragment>
            <h5>Poruke mojeg programa:</h5>
                <div className="message-box border rounded">
                    <ul>
                        {
                            programMessages.map((message, i) => (
                                <li key={i} onClick={() => history.push('/program-message/' + message.message_id)}>
                                    <div className="d-flex">
                                        <div className="content-holder">
                                            <i className="fas fa-envelope"/> {message.subject}
                                        </div>
                                        <div className="timestamp-holder">
                                            {message.timestamp}
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
        </React.Fragment>
    );
};
