import React, {useEffect, useState} from "react";
import {useParams} from 'react-router';
import {api} from "../../../Api";

export const ProgramMessagePreview = () => {
    const {id} = useParams();
    const [message, setMessage] = useState({});

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-message?message_id=' + id);
            if (res) setMessage(res.data);
        };
        awaitFunction();
    }, [id]);

    return (
        <div className='messages-content'>
            <ul className="list-group">
                <li className="list-group-item">Program: {message ? message.program : ''}</li>
                <li className="list-group-item">Predmet: {message ? message.subject : ''}</li>
                <li className="list-group-item">Datum: {message ? message.timestamp : ''}</li>
            </ul>
            <div className="message-holder-box">
                <h5>Poruka:</h5>
                <div className="message-box">
                    <p className="text-justify">
                        {message.content}
                    </p>
                </div>
            </div>
        </div>
    );
};
