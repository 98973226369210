import React, {useContext} from "react";
import {Input, Select, TextArea} from "../../Elements";
import {MessagesContext} from "./MessagesContext";

export const AddNewForm = () => {
    const {dispatch, subject, message_to, users, content} = useContext(MessagesContext);

    return (
        <form>
            <div className="form-group">
                <label htmlFor="messageSubject">Predmet poruke:</label>
                <Input
                    type="text"
                    className="form-control"
                    id="messageSubject"
                    placeholder="Upišite predmet poruke..."
                    value={subject}
                    validation={['empty']}
                    onChange={ev => dispatch({type: 'SET_SUBJECT', payload: ev.target.value})}/>
            </div>
            <div className="form-group">
                <label htmlFor="userSelect">Prima:</label>
                <Select
                    className="form-control custom-select"
                    id="userSelect"
                    value={message_to}
                    validation={['empty']}
                    onChange={ev => dispatch({type: 'SET_MESSAGE_TO', payload: ev.target.value})}>
                    <option value="">Odaberite primatelja...</option>
                    {users.map((user, i) => (
                        <option key={i} value={user.id}>{user.user}</option>
                    ))}
                </Select>
            </div>
            <div className="form-group">
                <label htmlFor="messageText">Poruka:</label>
                <TextArea
                    className="form-control"
                    id="messageText"
                    rows="5"
                    placeholder="Upišite text poruke..."
                    value={content}
                    validation={['empty']}
                    onChange={ev => dispatch({type: 'SET_CONTENT', payload: ev.target.value})}/>
            </div>
        </form>
    );
};
