import React, {useContext, useReducer} from "react";
import {api} from "../../Api";
import {GlobalContext} from "../../Context";

const initialFormState = {
    'new_password': '',
    'repeat_new_password': ''
};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_NEW_PASSWORD':
            return {...state, new_password: action.payload};
        case 'SET_REPEAT_NEW_PASSWORD':
            return {...state, repeat_new_password: action.payload};
        default:
            return state;
    }
};

export const ChangePassword = () => {
    const {setMessage, logout} = useContext(GlobalContext);
    const [formState, dispatchFormState] = useReducer(formReducer, initialFormState);

    const submitForm = async () => {
        if (formState.new_password !== '' && formState.repeat_new_password !== '') {
            if(formState.new_password === formState.repeat_new_password) {
                const res = await api.post('change-password', formState);
                if (res) {
                    logout();
                    setMessage({message: 'Uspjesno ste promjenili lozinku! Molimo Vas da se ponovo ulogirate sa novo lozinkom!', type: 'success'});
                }
            } else {
                setMessage({message: 'Lozinke nisu iste!', type: 'warning'});
            }
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    return (
        <div className="d-flex justify-content-center">
            <form className="mt-5">
                <div className="form-group">
                    <label htmlFor="new-password">Nova lozinka:</label>
                    <input
                        id="new-password"
                        className="form-control"
                        type="password"
                        value={formState.new_password}
                        onChange={e => dispatchFormState({type: 'SET_NEW_PASSWORD', payload: e.target.value})}/>
                </div>
                <div className="form-group">
                    <label htmlFor="repeat-new-password">Ponovi novu lozinku:</label>
                    <input
                        id="repeat-new-password"
                        className="form-control"
                        type="password"
                        value={formState.repeat_new_password}
                        onChange={e => dispatchFormState({
                            type: 'SET_REPEAT_NEW_PASSWORD',
                            payload: e.target.value
                        })}/>
                </div>
                <button className="btn btn-primary" onClick={submitForm}>Promjeni lozinku</button>
            </form>
        </div>
    );
};
