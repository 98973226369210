import React, {useContext, useEffect, useState} from "react";
import {api} from "../../../Api";
import {GlobalContext} from "../../../Context";

export const DocumentBox = () => {
    const mediaDownloadLink = process.env.REACT_APP_MEDIA_URL;
    const {userInfo} = useContext(GlobalContext);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-documents', {params: {program_id: userInfo.program_id}});
            if (res) setFiles(res.data);
        };
        awaitFunction();
    }, [userInfo.program_id]);

    return (
        <React.Fragment>
            <h5>Dokumenti mojeg programa:</h5>
            <div className="message-box border rounded">
                <ul>
                    {
                        files.map((file, i) => (
                            <li key={i}>
                                <a href={mediaDownloadLink + file.path} download target="_blank" rel="noopener noreferrer">
                                    <i className="fas fa-file-download"/> {file.name}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </React.Fragment>
    );
};
