import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {api} from "../../../Api";
import {DocumentTable} from "../Partials/DocumentTable";

export const ProgramStudent = () => {
    const history = useHistory();
    const [programInfo, setProgramInfo] = useState({});
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-student-program');
            if (res) setProgramInfo(res.data)
        };
        awaitFunction();
    }, []);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-messages', {params: {id: programInfo.program_id}});
            if (res) setMessages(res.data)
        };
        awaitFunction();
    }, [programInfo.program_id]);

    return (
        <React.Fragment>
            <ul className="list-group">
                <li className="list-group-item">Naziv: {programInfo ? programInfo.title : ''}</li>
                <li className="list-group-item">Profesor: {programInfo ? programInfo.teacher : ''}</li>
                <li className="list-group-item">Dolaznost na predmet: {programInfo ? programInfo.attendance : ''}</li>
            </ul>
            <table className="table table-bordered table-hover table-selectable table-sm mt-4">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Predmet</th>
                    <th scope="col">Poruka</th>
                    <th scope="col">Vrijeme</th>
                </tr>
                </thead>
                <tbody>
                {messages.map((m, i) => (
                    <tr key={i} onClick={() => history.push('/program-message/' + m.message_id)}>
                        <td>{m.subject}</td>
                        <td>{m.content}</td>
                        <td>{m.timestamp}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <DocumentTable programId={programInfo.program_id} />
        </React.Fragment>
    )
};
