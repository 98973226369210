import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {api} from "../../../Api";

export const ProgramTeacher = () => {
    const history = useHistory();
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const result = await api.get('get-teacher-programs');
            if (result) setPrograms(result.data);
        };
        awaitFunction();
    }, []);

    return (
        <React.Fragment>
            <table className="table table-bordered table-hover table-selectable table-sm">
                <thead className="thead-dark">
                <tr>
                    <th scope="col" width="4%" className="text-center">#</th>
                    <th scope="col">Naziv</th>
                    <th scope="col">Profesor</th>
                    <th scope="col">Broj polaznika</th>
                </tr>
                </thead>
                <tbody>
                {programs.map((pr, i) => (
                    <tr key={i} onClick={() => history.push('/program/' + pr.id)}>
                        <th scope="row" className="text-center">{i + 1}</th>
                        <td>{pr.name}</td>
                        <td>{pr.teacher}</td>
                        <td>{pr.student_count}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </React.Fragment>
    )
};
