import React, {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router';
import {api} from "../../../Api";
import {Button} from "../../../Elements";
import {AddNewForm} from "../AddNewForm";
import {FormModal} from "../..";
import {MessagesContext} from "../MessagesContext";

export const MessagePreview = () => {
    const {sendMessage, dispatch} = useContext(MessagesContext);

    const {id} = useParams();
    const [message, setMessage] = useState({});

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-private-message?id=' + id);
            if (res) {
                setMessage(res.data);
                dispatch({type: 'SET_USERS', payload: [{ 'id': res.data.id_from, 'user': res.data.from, 'email':  res.data.email_from}]});
            }
        };
        awaitFunction();
    }, [id, dispatch]);

    return (
        <div className='messages-content'>
            <div className="content-header text-right">
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#formModal">
                    <i className="fas fa-paper-plane"/> Odgovori
                </button>
            </div>
            <ul className="list-group">
                <li className="list-group-item">Od: {message ? `${message.from}` : ''}</li>
                <li className="list-group-item">Prima: {message ? `${message.to}` : ''}</li>
                <li className="list-group-item">Predmet: {message ? message.subject : ''}</li>
                <li className="list-group-item">Datum: {message ? message.timestamp : ''}</li>
            </ul>
            <div className="message-holder-box">
                <h5>Poruka:</h5>
                <div className="message-box">
                    <p className="text-justify">
                        {message.content}
                    </p>
                </div>
            </div>
            <FormModal
                onSendMessage={sendMessage}
                title="Pošalji poruku"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendMessage}
                >
                    Pošalji
                </Button>}
            >
                <AddNewForm/>
            </FormModal>
        </div>
    );
};
