import React, { useContext, useEffect } from 'react';
import {
    Sidebar,
    Navigationbar,
    Error
} from '../Components';
import { GlobalContext } from '../Context';
import {api} from "../Api";

export const MainLayout = props => {
    const {sidebarVisible, setUserInfo} = useContext(GlobalContext);

    useEffect(() => {
        const awaitFunction = async () => {
            const result =  await api.get('/get-user');
            if (result) setUserInfo(result.data);
        };
        awaitFunction();
    }, [setUserInfo]);

    return (
        <div className={`d-flex ${sidebarVisible ? '' : 'toggled'}`} id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
                <Navigationbar />
                <div className="container-fluid">
                    <Error />
                    {props.children}
                </div>
            </div>
        </div>
    )
};
