import React, {useState} from "react";
import {validateEmail} from "../Utils";

export const Input = props => {
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');

    const {validation, className, ...properties} = props;

    const validate = ev => {
        const value = ev.target.value;

        if (validation.includes('empty') && value === '') {
            setIsValid(false);
            setValidationMessage('Polje nemože biti prazno')
        } else if (validation.includes('email') && validateEmail(value)) {
            setIsValid(false);
            setValidationMessage('Unos mora biti email')
        } else {
            setIsValid(true);
            setValidationMessage('')
        }
    };

    return (
        <React.Fragment>
            <input
                className={`${className} ${isValid ? '' : 'is-invalid'}`}
                {...properties}
                onBlur={(ev) => validate(ev)} />
            <div className="invalid-feedback">
                {validationMessage}
            </div>
        </React.Fragment>
    )
};
