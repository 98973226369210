import React, {createContext, useCallback, useContext, useReducer} from "react";
import $ from 'jquery';
import {api} from "../../Api";
import {GlobalContext} from "../../Context";

const initialState = {
    'subject': '',
    'message_to': '',
    'content': '',
    'users': [],
    'message_to_delete': '',
    'tabData': [
        {
            'title': 'Inbox',
            'active': true
        },
        {
            'title': 'Poslane',
            'active': false
        }
    ],
    'contentData': [
        {
            'content': [],
            'active': true
        },
        {
            'content': [],
            'active': false
        }
    ]
};

// TODO: split into multiple reducers!!
const reduce = (state, action) => {
    switch (action.type) {
        case 'SET_SUBJECT':
            return {...state, subject: action.payload};
        case 'SET_MESSAGE_TO':
            return {...state, message_to: action.payload};
        case 'SET_CONTENT':
            return {...state, content: action.payload};
        case 'EMPTY_MESSAGE_FORM':
            return {...state, subject: '', message_to: '', content: ''};
        case 'SET_MESSAGE_TO_DELETE':
            return {...state, message_to_delete: action.payload};
        case 'SET_USERS':
            return {...state, users: action.payload};
        case 'SET_TAB_DATA':
            return {...state, tabData: action.payload};
        case 'SET_CONTENT_DATA':
            return {...state, contentData: action.payload};
        default:
            return state;
    }
};

export const MessagesContext = createContext({});

export const MessagesContextProvider = props => {
    const {setMessage} = useContext(GlobalContext);
    const [state, dispatch] = useReducer(reduce, initialState);

    const getUsers = useCallback(async () => {
        const res = await api.get('/get-all-users');
        if (res) dispatch({type: 'SET_USERS', payload: res.data});
    }, []);

    const getPrivateMessages = useCallback(async () => {
        const res = await api.get('get-private-messages');
        if (res) {
            dispatch({
                type: 'SET_CONTENT_DATA', payload: [
                    {
                        'content': res.data.inbox,
                        'active': true
                    },
                    {
                        'content': res.data.sent,
                        'active': false
                    }
                ]
            });
        }
    }, []);

    const sendMessage = async () => {
        if (!(state.subject === '' || state.message_to === '' || state.content === '')) {
            const res = await api.post('/post-private-message', state);
            if (res) {
                dispatch({
                    type: 'SET_CONTENT_DATA', payload: [
                        {
                            'content': res.data.inbox,
                            'active': state.contentData[0].active
                        },
                        {
                            'content': res.data.sent,
                            'active': state.contentData[1].active
                        }
                    ]
                });
                $('#formModal').modal('hide');
                dispatch({type: 'EMPTY_MESSAGE_FORM'});
                setMessage({message: 'Poruka uspješno poslana', type: 'success'});
            }
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    const deleteMessage = async (id) => {
        const res = await api.delete('delete-private-message', {params: {'id': id}});
        if (res) {
            dispatch({
                    type: 'SET_CONTENT_DATA', payload: [
                        {
                            'content': res.data.inbox,
                            'active': state.contentData[0].active
                        },
                        {
                            'content': res.data.sent,
                            'active': state.contentData[1].active
                        }
                    ]
                });
                $('#confirmModal').modal('hide');
                dispatch({type: 'SET_MESSAGE_TO_DELETE', payload: ''});
                setMessage({message: 'Poruka uspješno obrisana', type: 'success'});
        }
    };

    const switchActiveTab = (ev, index) => {
        ev.preventDefault();
        const newTabData = state.tabData.map((tab, i) => (
            {
                'title': tab.title,
                'active': i === index
            }
        ));
        const newContentData = state.contentData.map((content, i) => (
            {
                'content': content.content,
                'active': i === index
            }
        ));
        dispatch({type: 'SET_TAB_DATA', payload: newTabData});
        dispatch({type: 'SET_CONTENT_DATA', payload: newContentData});
    };

    const value = {
        subject: state.subject,
        message_to: state.message_to,
        content: state.content,
        users: state.users,
        message_to_delete: state.message_to_delete,
        tabData: state.tabData,
        contentData: state.contentData,
        getUsers: getUsers,
        getPrivateMessages: getPrivateMessages,
        dispatch: dispatch,
        sendMessage: sendMessage,
        switchActiveTab: switchActiveTab,
        deleteMessage: deleteMessage
    };

    return (
        <MessagesContext.Provider value={value}>
            {props.children}
        </MessagesContext.Provider>
    );
};
