import React, {useState, useEffect, useContext, useReducer} from "react";
import {useParams} from 'react-router';
import {Redirect, useHistory} from 'react-router-dom';
import $ from 'jquery';
import {api} from "../../Api";
import {GlobalContext} from "../../Context";
import {FormModal} from "..";
import {Button, Input, TextArea} from "../../Elements";
import {DocumentTable} from "./Partials/DocumentTable";

const messageFormInitialState = {
    'subject': '',
    'content': ''
};

const messageFormReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SUBJECT':
            return {...state, subject: action.payload};
        case 'SET_CONTENT':
            return {...state, content: action.payload};
        case 'EMPTY_FORM':
            return {...state, subject: '', content: ''};
        default:
            return state;
    }
};

export const ProgramPreview = () => {
    const {userInfo, setMessage} = useContext(GlobalContext);
    const {id} = useParams();
    const history = useHistory();
    const [messageFormState, messageFormDispatch] = useReducer(messageFormReducer, messageFormInitialState);

    const [programInfo, setProgramInfo] = useState({});
    const [students, setStudents] = useState([]);
    const [messages, setMessages] = useState([]);
    const [attendanceInfo, setAttendanceInfo] = useState({id: 0, attendance: 0});

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-students', {params: {id: id}});
            if (res) setStudents(res.data);
        };
        awaitFunction();
    }, [id]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-teacher-program', {params: {id: id}});
            if (res) setProgramInfo(res.data)
        };
        awaitFunction();
    }, [id]);

    useEffect(() => {
        const awaitFunction = async () => {
            const res = await api.get('get-program-messages', {params: {id: id}});
            if (res) setMessages(res.data)
        };
        awaitFunction();
    }, [id]);

    const saveAttendance = async () => {
        if (attendanceInfo.attendance !== '') {
            const res = await api.post('post-attendance', {
                attendance_id: attendanceInfo.id,
                program_id: id,
                attendance: attendanceInfo.attendance
            });
            if (res) {
                setStudents(res.data);
                $('#attendanceModal').modal('hide');
            }
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    const saveMessage = async () => {
        if (messageFormState.subject !== '' || messageFormState.content !== '') {
            const res = await api.post('post-program-message', {
                program_id: id,
                ...messageFormState
            });
            if (res) {
                setMessages(res.data);
                messageFormDispatch({type: 'EMPTY_FORM'});
                $('#newMessageModal').modal('hide');
            }
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    const onAttendanceChanged = (ev) => {
        const value = ev.target.value;
        setAttendanceInfo(prevState => ({...prevState, attendance: value}));
    };

    if (userInfo.permission === 'student') return <Redirect to='/'/>;

    return (
        <React.Fragment>
            <ul className="list-group">
                <li className="list-group-item">Naziv: {programInfo.title ? programInfo.title : ''}</li>
                <li className="list-group-item">Profesor: {programInfo.teacher ? programInfo.teacher : ''}</li>
            </ul>
            <table className="table table-bordered table-sm mt-4">
                <thead className="thead-dark">
                <tr>
                    <th scope="col" width="4%" className="text-center">#</th>
                    <th scope="col">Ime</th>
                    <th scope="col">Prezime</th>
                    <th scope="col">Dolaznost</th>
                </tr>
                </thead>
                <tbody>
                {students.map((st, i) => (
                    <tr key={i}>
                        <th scope="row" className="text-center">{i + 1}</th>
                        <td>{st.name}</td>
                        <td>{st.surname}</td>
                        <td className="with-edit">
                            <div className="with-edit-content">
                                {st.attendance}
                            </div>
                            <div className="with-edit-icon">
                                <a
                                    className='attendance-edit'
                                    href="#/"
                                    data-toggle="modal"
                                    data-target="#attendanceModal"
                                    onClick={ev => {
                                        ev.stopPropagation();
                                        setAttendanceInfo({id: st.id, attendance: st.attendance})
                                    }}
                                >
                                    <i className="fas fa-edit"/>
                                </a>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="content-header text-right mt-5">
                <button className="btn btn-primary" data-toggle="modal" data-target="#newMessageModal">
                    <i className="fas fa-paper-plane"/> Nova poruka
                </button>
            </div>
            <table className="table table-bordered table-hover table-selectable table-sm mt-4">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Predmet</th>
                    <th scope="col">Poruka</th>
                    <th scope="col">Vrijeme</th>
                </tr>
                </thead>
                <tbody>
                {messages.map((m, i) => (
                    <tr key={i} onClick={() => history.push('/program-message/' + m.message_id)}>
                        <td>{m.subject}</td>
                        <td>{m.content}</td>
                        <td>{m.timestamp}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <DocumentTable programId={id} />
            <FormModal
                id="attendanceModal"
                title="Upisi dolaznost"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveAttendance}
                >
                    Spremi
                </Button>}
            >
                <form>
                    <div className="form-group">
                        <label htmlFor="programName">Naziv:</label>
                        <Input
                            className="form-control"
                            type="number"
                            min="0"
                            value={attendanceInfo.attendance}
                            validation={['empty']}
                            onChange={ev => onAttendanceChanged(ev)}/>
                    </div>
                </form>
            </FormModal>
            <FormModal
                id="newMessageModal"
                title="Dodaj novu predmetnu poruku"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveMessage}
                >
                    Spremi
                </Button>}
            >
                <form>
                    <div className="form-group">
                        <label htmlFor="messageSubject">Predmet poruke:</label>
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Upišite premdet poruke..."
                            value={messageFormState.subject}
                            validation={['empty']}
                            onChange={ev => messageFormDispatch({type: 'SET_SUBJECT', payload: ev.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="messageText">Poruka:</label>
                        <TextArea
                            className="form-control"
                            id="messageText"
                            rows="5"
                            placeholder="Upišite text poruke..."
                            value={messageFormState.content}
                            validation={['empty']}
                            onChange={ev => messageFormDispatch({type: 'SET_CONTENT', payload: ev.target.value})}/>
                    </div>
                </form>
            </FormModal>
        </React.Fragment>
    )
};
