import React, {useContext, useEffect, useReducer, useState} from 'react';
import {api} from "../../Api";
import {Button, Input, Select} from "../../Elements";
import {FormModal} from "..";
import $ from "jquery";
import {GlobalContext} from "../../Context";
import {validateEmail} from "../../Utils";

const initialState = {
    'email': '',
    'name': '',
    'surname': '',
    'password': '',
    'program': ''
};

const formReducer = (state, action) => {
    switch (action.type) {
        case 'SET_EMAIL':
            return {...state, email: action.payload};
        case 'SET_NAME':
            return {...state, name: action.payload};
        case 'SET_SURNAME':
            return {...state, surname: action.payload};
        case 'SET_PASSWORD':
            return {...state, password: action.payload};
        case 'SET_PROGRAM':
            return {...state, program_id: action.payload};
        case 'EMPTY_FORM':
            return {...state, email: '', name: '', surname: '', password: ''};
        default:
            return state;
    }
};

export const Students = () => {
    const {setMessage} = useContext(GlobalContext);
    const [formState, dispatch] = useReducer(formReducer, initialState);
    const [students, setStudents] = useState([]);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        const awaitFunction = async () => {
            const result = await api.get('get-students');
            if (result) setStudents(result.data)
        };
        awaitFunction();
    }, []);

    useEffect(() => {
        const awaitFunction = async () => {
            const result = await api.get('get-programs');
            if (result) setPrograms(result.data);
        };
        awaitFunction();
    }, []);

    const createStudent = async () => {
        if (!validateEmail(formState.email)
            && formState.email !== ''
            && formState.password !== ''
            && formState.name !== ''
            && formState.surname !== ''
        ) {
            const result = await api.post('post-student', formState);
            if (result) setStudents(result.data);
            $('#addStudentModal').modal('hide');
            setMessage({message: 'Student uspješno kreiran', type: 'success'});
            dispatch({type: 'EMPTY_FORM'})
        } else {
            setMessage({message: 'Popunite potrebna polja', type: 'warning'});
        }
    };

    return (
        <React.Fragment>
            <div className="content-header text-right">
                <button className="btn btn-primary" data-toggle="modal" data-target="#addStudentModal">
                    <i className="fas fa-plus-circle"/> Novi polaznik
                </button>
            </div>
            <table className="table table-bordered table-sm">
                <thead className="thead-dark">
                <tr>
                    <th scope="col" width="4%" className="text-center">#</th>
                    <th scope="col">Ime</th>
                    <th scope="col">Prezime</th>
                    <th scope="col">Program</th>
                </tr>
                </thead>
                <tbody>
                {students.map((tr, i) => (
                    <tr key={i}>
                        <th scope="row" className="text-center">{i + 1}</th>
                        <td>{tr.name}</td>
                        <td>{tr.surname}</td>
                        <td>{tr.program}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <FormModal
                id='addStudentModal'
                title="Dodaj novog polaznika"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={createStudent}
                >
                    Spremi
                </Button>}
            >
                <form autoComplete="new-password">
                    <div className="form-group">
                        <label htmlFor="studentEmail">Email:</label>
                        <Input
                            autoComplete="new-password"
                            type="email"
                            className="form-control"
                            id="studentEmail"
                            placeholder="Upišite email za login..."
                            value={formState.email}
                            validation={['empty', 'email']}
                            onChange={ev => dispatch({type: 'SET_EMAIL', payload: ev.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="studentPassword">Lozinka:</label>
                        <Input
                            autoComplete="new-password"
                            type="password"
                            className="form-control"
                            id="studentPassword"
                            placeholder="Upišite lozinku za polaznika..."
                            value={formState.password}
                            validation={['empty']}
                            onChange={ev => dispatch({type: 'SET_PASSWORD', payload: ev.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="studentName">Ime:</label>
                        <Input
                            type="text"
                            className="form-control"
                            id="studentName"
                            placeholder="Upišite ime studenta..."
                            value={formState.name}
                            validation={['empty']}
                            onChange={ev => dispatch({type: 'SET_NAME', payload: ev.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="studentSurname">Prezime:</label>
                        <Input
                            type="text"
                            className="form-control"
                            id="studentSurname"
                            placeholder="Upišite prezime studenta..."
                            value={formState.surname}
                            validation={['empty']}
                            onChange={ev => dispatch({type: 'SET_SURNAME', payload: ev.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="programSelect">Program:</label>
                        <Select
                            className="form-control custom-select"
                            id="programSelect"
                            value={formState.program_id}
                            validation={['']}
                            onChange={ev => dispatch({type: 'SET_PROGRAM', payload: ev.target.value})}>
                            <option value="">Odaberite program...</option>
                            {programs.map((program, i) => (
                                <option key={i} value={program.id}>{program.name}</option>
                            ))}
                        </Select>
                    </div>
                </form>
            </FormModal>
        </React.Fragment>
    );
};
