import React, {useState} from "react";

export const Select = (props) => {
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const {validation, className, ...properties} = props;

    const validate = ev => {
        const value = ev.target.value;

        if (validation.includes('empty') && value === '') {
            setIsValid(false);
            setValidationMessage('Polje nemože biti prazno')
        } else {
            setIsValid(true);
            setValidationMessage('')
        }
    };

    return (
        <React.Fragment>
            <select
                className={`${className} ${isValid ? '' : 'is-invalid'}`}
                {...properties}
                onBlur={(ev) => validate(ev)}>
                {props.children}
            </select>
            <div className="invalid-feedback">
                {validationMessage}
            </div>
        </React.Fragment>
    )
};
