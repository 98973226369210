import React, {createContext, useState, useEffect, useMemo} from "react";
import {api, handleGlobalApiErrors, publicApi, refreshToken} from "../Api";

const getInitialState = () => {
    return !!localStorage.getItem('refresh_token');
};

export const GlobalContext = createContext({});

export const GlobalContextProvider = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(getInitialState());
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({message: '', type: ''});
    const [userInfo, setUserInfo] = useState({});
    const [sidebarVisible, setSidebarVisible] = useState(true);


    const ejectInterceptor = useMemo(() => {
        const interceptor = handleGlobalApiErrors(setMessage, setIsLoggedIn);
        return () => api.interceptors.request.eject(interceptor);
    }, []);

    useEffect(() => {
        return ejectInterceptor;
    }, [ejectInterceptor]);

    useEffect(() => {
        setIsLoading(true);
        const awaitFunction = async () => {
            const isRefreshed = await refreshToken();
            if(isRefreshed) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        awaitFunction();
        setIsLoading(false);
    }, []);

    const loginHandler = async (email, password) => {
        try {
            const res = await publicApi.post('/login', { username: email, password: password });
            localStorage.setItem('access_token', res.data.access);
            localStorage.setItem('refresh_token', res.data.refresh);
            setIsLoggedIn(true);
            setMessage({message: 'Uspješno ste prijavljeni.', type: 'success'});
        } catch (e) {
            if (e.response) {
                setMessage({message: e.response.data.error_message, type: 'danger'});
            } else {
                setMessage({message: 'Nešto je pošlo po zlu!', type: 'danger'})
            }
        }
    };

    const logoutHandler = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setMessage({message: 'Uspješno ste odjavljeni', type: 'success'});
    };

    const value = {
        isLoggedIn: isLoggedIn,
        isLoading: isLoading,
        message: message,
        setMessage: setMessage,
        login: loginHandler,
        logout: logoutHandler,
        userInfo: userInfo,
        setUserInfo: setUserInfo,
        sidebarVisible: sidebarVisible,
        setSidebarVisible: setSidebarVisible
    };

    return (
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
    );
};
