import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {FormModal} from '../Partials/FormModal'
import {MessagesContext} from "./MessagesContext";
import {AddNewForm} from './AddNewForm';
import {Button} from '../../Elements';
import {ConfirmModal} from "..";

export const Messages = () => {
    const {
        dispatch,
        tabData,
        contentData,
        switchActiveTab,
        sendMessage,
        getPrivateMessages,
        getUsers,
        deleteMessage,
        message_to_delete
    } = useContext(MessagesContext);
    const history = useHistory();

    useEffect(() => {
        getPrivateMessages()
    }, [getPrivateMessages]);

    useEffect(() => {
        getUsers()
    }, [getUsers]);

    return (
        <div className="messages-content">
            <div className="content-header text-right">
                <button className="btn btn-primary" data-toggle="modal" data-target="#formModal">
                    <i className="fas fa-paper-plane"/> Nova poruka
                </button>
            </div>
            <ul className="nav nav-tabs">
                {
                    tabData.map((tab, i) => (
                        <li className="nav-item" key={i}>
                            <a className={`nav-link ${tab.active ? 'active' : ''}`} href="#/"
                               onClick={(ev) => switchActiveTab(ev, i)}>{tab.title}</a>
                        </li>
                    ))
                }
            </ul>
            <div className="tab-content">
                {
                    contentData.map((tabContent, i) => (
                        <div className={`tab-pane fade ${tabContent.active ? 'show active' : ''}`} key={i}>
                            <table className="table table-bordered table-hover table-selectable table-sm">
                                <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Predmet</th>
                                    <th scope="col">Od</th>
                                    <th scope="col">Prima</th>
                                    <th scope="col">Datum</th>
                                    <th scope="col" width="6%">Akcije</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tabContent.content.map((cnt, i) => (
                                    <tr key={i} onClick={() => history.push('/messages/' + cnt.id)}>
                                        <td>{cnt.subject}</td>
                                        <td>{cnt.from}</td>
                                        <td>{cnt.to}</td>
                                        <td>{cnt.timestamp}</td>
                                        <td className="text-center">
                                            <button
                                                className="btn btn-danger btn-sm"
                                                data-toggle="modal"
                                                data-target="#confirmModal"
                                                onClick={ev => { ev.stopPropagation(); dispatch({type: 'SET_MESSAGE_TO_DELETE', payload: cnt.id})}}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ))
                }
            </div>
            <FormModal
                onSendMessage={sendMessage}
                title="Pošalji poruku"
                submitButton={<Button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendMessage}
                >
                    Pošalji
                </Button>}
            >
                <AddNewForm/>
            </FormModal>
            <ConfirmModal
                title="Brisanje"
                question="Jeste li sigurni da želite obrisati poruku?"
                submitButton={
                    <button className="btn btn-danger" onClick={() => deleteMessage(message_to_delete)}>Obriši</button>
                }
            />
        </div>
    );
};
