import React, {useContext} from 'react';
import {GlobalContext} from "../../Context";

export const Sidebar = () => {
    const {userInfo} = useContext(GlobalContext);
    let adminRoutes = <></>;
    if (userInfo.permission === 'admin') {
        adminRoutes = (
        <React.Fragment>
            <a href="/#/students" className="list-group-item list-group-item-action bg-light">Polaznici</a>
            <a href="/#/teachers" className="list-group-item list-group-item-action bg-light">Nastavnici</a>
        </React.Fragment>
    );
    }

    return (
        <div className="bg-light border-right" id="sidebar-wrapper">
            <div className="sidebar-heading">ePlatforma</div>
            <div className="list-group list-group-flush">
                <a href="/#/" className="list-group-item list-group-item-action bg-light">Početna</a>
                <a href="/#/program" className="list-group-item list-group-item-action bg-light">Program</a>
                <a href="/#/documents" className="list-group-item list-group-item-action bg-light">Dokumenti</a>
                <a href="/#/messages" className="list-group-item list-group-item-action bg-light">Poruke</a>
                {adminRoutes}
            </div>
        </div>
    );
};
